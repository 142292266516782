import { arrayRemoveAtIndex, EMPTY_ARRAY, EMPTY_STRING, sortIgnoreCase } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FieldUnit, SpectrumValueConstraintFields, useFieldUnitsQueryLazyQuery } from "@regrello/graphql-api";
import { RegrelloButton, RegrelloChip, RegrelloIcon, RegrelloTooltipV4 } from "@regrello/ui-core";
import {
  AddOption,
  AlphabetizeOptions,
  AlphabetizeOptionsTooltip,
  CreateFieldDialogFieldTypeHelperText,
  CurrencySymbol,
  DataFormat,
  HelperText,
  Name,
  QueryToastMessageError,
  Type,
} from "@regrello/ui-strings";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFieldArray, UseFormReturn, useWatch } from "react-hook-form";

import { RegrelloDraggableRowItem } from "./RegrelloDraggableRowItem";
import { ValidationRules } from "../../../../../../constants/globalConstants";
import { useErrorHandler } from "../../../../../../utils/hooks/useErrorHandler";
import { UserFieldPlugin } from "../../../../../molecules/customFields/plugins/UserFieldPlugin";
import { RegrelloFormFieldSelectOption } from "../../../../../molecules/formFields/_internal/selectOptions/RegrelloFormFieldSelectOption";
import {
  RegrelloControlledFormFieldSelect,
  RegrelloControlledFormFieldSwitch,
  RegrelloControlledFormFieldTextMultiline,
} from "../../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import { RegrelloControlledFormFieldText } from "../../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";
import { SpectrumUserFieldPluginDecorator } from "../../../../../molecules/spectrumFields/SpectrumUserFieldPluginDecorator";
import { SpectrumFieldPluginDecorator } from "../../../../../molecules/spectrumFields/types/SpectrumFieldPluginDecorator";
import {
  ALLOW_MULTIPLE_PARTY_ARG,
  FrontendValueConstraintRuleName,
  getNumberOfArgsByConstraint,
} from "../../../../../molecules/spectrumFields/utils/spectrumFieldConstraintUtils";

export interface ConfigureSpectrumFieldFormFormFields {
  name: string;
  helpText: string;
  pluginUri: string | undefined;
  allowedValues: Array<{ value: string }>;
  fieldUnit?: FieldUnit;
  isValueConstraintsEnabled: boolean;
  valueConstraints: Array<{
    constraint: SpectrumValueConstraintFields;
    args: string[];
  }>;
}

export interface ConfigureSpectrumFieldFormProps {
  spectrumFieldPlugins: Array<SpectrumFieldPluginDecorator<unknown>>;

  /** Callback that retrieves the full plugin class with the uri. */
  getPluginByUri: (pluginUri: string) => SpectrumFieldPluginDecorator<unknown> | undefined;

  /**
   * The name of the form field that will be focused when the dialog is open. Currently only "name"
   * and "helpText" is supported
   */
  focusField?: string;

  form: UseFormReturn<ConfigureSpectrumFieldFormFormFields>;

  /** The `mode` property determines whether the field **type** will be editable. */
  mode?: "create" | "edit";

  loadedValueConstraints: SpectrumValueConstraintFields[];
}

export const ConfigureSpectrumFieldForm = React.memo<ConfigureSpectrumFieldFormProps>(
  function ConfigureSpectrumFieldFormFn({
    getPluginByUri,
    spectrumFieldPlugins,
    form,
    mode = "create",
    loadedValueConstraints,
    focusField = "name",
  }) {
    const { handleError } = useErrorHandler();
    const selectedPluginUri = useWatch({ control: form.control, name: "pluginUri" });
    const isValueConstraintsEnabled = useWatch({ control: form.control, name: "isValueConstraintsEnabled" });
    useWatch({ control: form.control });

    const selectedPlugin = useMemo(
      () => (selectedPluginUri != null ? getPluginByUri(selectedPluginUri) : undefined),
      [getPluginByUri, selectedPluginUri],
    );

    const isSelectedPluginUriNeedsFieldUnit = selectedPlugin?.isNeedsFieldUnit?.();
    const isSelectedPluginUriNeedsAllowedValues = selectedPlugin?.hasAllowedValues?.();

    const [getFieldUnitsAsync, fieldUnitsResult] = useFieldUnitsQueryLazyQuery({
      onError: () => handleError(QueryToastMessageError),
    });
    const fieldUnits = fieldUnitsResult?.data?.fieldUnits ?? EMPTY_ARRAY;

    useEffect(() => {
      if (isSelectedPluginUriNeedsFieldUnit) {
        void getFieldUnitsAsync();
      }
    }, [isSelectedPluginUriNeedsFieldUnit, getFieldUnitsAsync]);

    const {
      fields: allowedValueRows,
      append: appendAllowedValue,
      remove: removeAllowedValue,
      replace: replaceAllowedValues,
      move: moveAllowedValue,
    } = useFieldArray({
      control: form.control,
      name: "allowedValues",
    });

    const {
      fields: constraintRows,
      append: appendConstraint,
      update: updateConstraint,
      remove: removeConstraint,
    } = useFieldArray({
      control: form.control,
      name: "valueConstraints",
    });

    const updateApplicableConstraints = useCallback(
      (nextPlugin: SpectrumFieldPluginDecorator<unknown> | null) => {
        const applicableConstraints =
          nextPlugin?.findValueConstraintsFromLoadedValueConstraints(loadedValueConstraints) ?? EMPTY_ARRAY;

        // (hchen): Remove all previous constraints
        removeConstraint();

        appendConstraint(
          applicableConstraints.map((constraint) => {
            if (constraint.valueConstraintRule === FrontendValueConstraintRuleName.MAX_PARTY) {
              return {
                constraint,
                args: new Array(getNumberOfArgsByConstraint(constraint)).fill(ALLOW_MULTIPLE_PARTY_ARG),
              };
            }
            return {
              constraint,
              args: new Array(getNumberOfArgsByConstraint(constraint)).fill(EMPTY_STRING),
            };
          }),
        );
      },
      [appendConstraint, loadedValueConstraints, removeConstraint],
    );

    const sortedPluginUris = useMemo(() => {
      const creationAllowedFieldPlugins = spectrumFieldPlugins.filter((plugin) => plugin.isCreateAndEditAllowed);
      return sortIgnoreCase(creationAllowedFieldPlugins, (plugin) => plugin.getFieldDisplayName()).map(
        (plugin) => plugin.uri,
      );
    }, [spectrumFieldPlugins]);

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!event.metaKey && event.key === "Enter" && isSelectedPluginUriNeedsAllowedValues) {
          appendAllowedValue({ value: "" });
        }
      },
      [appendAllowedValue, isSelectedPluginUriNeedsAllowedValues],
    );

    // (hchen): Add a input field at the first time a select type is chosen. This is not only a UX
    // nicety but also necessary because there must be at least one option for the select types.
    useEffect(() => {
      if (isSelectedPluginUriNeedsAllowedValues && allowedValueRows.length === 0) {
        appendAllowedValue({ value: "" });
      }
    }, [allowedValueRows.length, appendAllowedValue, isSelectedPluginUriNeedsAllowedValues]);

    const renderFieldUnitOption = useCallback((fieldUnit: FieldUnit) => {
      return (
        <RegrelloFormFieldSelectOption
          mainSnippets={[{ highlight: false, text: getFieldUnitDisplayName(fieldUnit) }]}
        />
      );
    }, []);

    const renderPluginOption = useCallback(
      (spectrumFieldUri?: string | null) => {
        if (spectrumFieldUri == null) {
          return null;
        }

        const plugin = getPluginByUri(spectrumFieldUri);
        if (plugin == null) {
          return null;
        }
        return (
          <RegrelloFormFieldSelectOption
            mainSnippets={[{ highlight: false, text: plugin.getFieldDisplayName() }]}
            startAdornment={
              <div className="mr-1">
                <RegrelloIcon iconName={plugin.getIconName()} />
              </div>
            }
          />
        );
      },
      [getPluginByUri],
    );

    const renderPluginConstraints = useCallback(() => {
      const shouldDisableConstraintForUserField =
        selectedPlugin?.uri === new SpectrumUserFieldPluginDecorator(UserFieldPlugin).uri && mode === "edit";
      return selectedPlugin?.renderValueConstraints({
        constraints: constraintRows,
        disabled: !(selectedPlugin?.isCreateAndEditAllowed ?? true) || shouldDisableConstraintForUserField,
        form,
        focusField: focusField as `valueConstraints.${number}.args.${number}`,
        updateConstraint,
      });
    }, [constraintRows, focusField, form, mode, selectedPlugin, updateConstraint]);

    const handlePluginChange = useCallback(
      (_name: string, nextPluginUri: string | null) => {
        const nextPlugin = (nextPluginUri != null ? getPluginByUri(nextPluginUri) : null) ?? null;
        if (selectedPlugin?.hasAllowedValues?.() && !nextPlugin?.hasAllowedValues?.()) {
          form.resetField("allowedValues", { defaultValue: EMPTY_ARRAY });
        }
        if (selectedPlugin?.isNeedsFieldUnit?.() && !nextPlugin?.isNeedsFieldUnit?.()) {
          form.resetField("fieldUnit", { defaultValue: undefined });
        }
        updateApplicableConstraints(nextPlugin);
      },
      [form, getPluginByUri, selectedPlugin, updateApplicableConstraints],
    );

    useEffect(() => {
      // (hchen): If the form has default values, don't try to reset the applicable constraints with
      // `handlePluginChange`
      const valueConstraints = form.getValues("valueConstraints");
      if (valueConstraints?.length > 0) {
        return;
      }

      const pluginUri = form.getValues("pluginUri");
      if (pluginUri == null) {
        return;
      }
      handlePluginChange("pluginUri", pluginUri);
    }, [form, handlePluginChange]);

    const [isAddingViaPaste, setIsAddingViaPaste] = useState(false);

    const handleSelectOptionPaste = useCallback(
      (event: React.ClipboardEvent<HTMLInputElement>, index: number) => {
        const pastedText = event.clipboardData.getData("text");
        if (pastedText == null) {
          return;
        }
        if (pastedText.split("\n").length > 1) {
          setIsAddingViaPaste(true);
          // Insert all pasted values as new options.
          let nextAllowedValues: Array<{ value: string }> = [];
          const currAllowedValues = form.getValues("allowedValues");
          if (event.currentTarget.value.length === 0) {
            // (clewis): Prevent default to avoid pasting the text into this field. Instead, remove
            // this field because we're about to add N new options below it.
            event.preventDefault();
            nextAllowedValues = arrayRemoveAtIndex(currAllowedValues, index);
          }

          // (clewis): Try not to block the UI thread when pasting lots of values. The real fix here
          // would be virtualized rendering, but this is a rare enough case that I'm not
          // implementing that here yet.
          requestAnimationFrame(() => {
            const pastedLines = pastedText.split("\n");
            const pastedAllowedValues = pastedLines.map((value) => ({
              value: value.trim(),
            }));
            nextAllowedValues.push(...pastedAllowedValues);
            replaceAllowedValues(nextAllowedValues);
            setIsAddingViaPaste(false);
          });
        }
      },
      [form, replaceAllowedValues],
    );

    const [isSorting, setIsSorting] = useState(false);

    const sortOptionsAlphabetically = useCallback(() => {
      // (clewis): Use requestAnimationFrame to avoid blocking the UI if there are many options.
      setIsSorting(true);
      requestAnimationFrame(() => {
        const currentOptions = form.getValues("allowedValues"); // Get the latest value, else there can be weird bugs.
        const sortedOptions = sortIgnoreCase(currentOptions, (option) => option.value);
        form.setValue("allowedValues", sortedOptions);
        setIsSorting(false);
      });
    }, [form]);

    // (clewis): Don't use useMemo here because values can be stale in allowedValueRows.
    const latestAllowedValueRows = form.getValues("allowedValues");
    const isOptionsAlphabetized = latestAllowedValueRows.every((option, index) => {
      return index === 0 || option.value.localeCompare(latestAllowedValueRows[index - 1].value) >= 0;
    });

    return (
      <div onKeyDown={onKeyDown}>
        {/* Field name */}
        <RegrelloControlledFormFieldText
          autoFocus={focusField === "name"}
          controllerProps={{
            control: form.control,
            name: "name",
            rules: ValidationRules.REQUIRED,
          }}
          dataTestId={DataTestIds.CONFIGURE_SPECTRUM_FIELD_DIALOG_NAME}
          disabled={!(selectedPlugin?.isCreateAndEditAllowed ?? true)}
          isRequiredAsteriskShown={true}
          label={Name}
        />

        {/* Field helper text */}
        <RegrelloControlledFormFieldTextMultiline
          autoFocus={focusField === "helpText"}
          controllerProps={{
            control: form.control,
            name: "helpText",
          }}
          dataTestId={DataTestIds.CONFIGURE_SPECTRUM_FIELD_DIALOG_HELPER_TEXT}
          disabled={!(selectedPlugin?.isCreateAndEditAllowed ?? true)}
          label={HelperText}
        />

        {/* Field type */}
        <RegrelloControlledFormFieldSelect
          controllerProps={{
            control: form.control,
            name: "pluginUri",
            rules: ValidationRules.REQUIRED,
          }}
          dataTestId={DataTestIds.CONFIGURE_SPECTRUM_FIELD_DIALOG_FIELD_TYPE}
          disabled={mode === "edit"}
          getOptionLabel={(option) => {
            return getPluginByUri(option)?.getFieldDisplayName() ?? EMPTY_STRING;
          }}
          helperText={selectedPlugin?.getHelperText?.() ?? CreateFieldDialogFieldTypeHelperText}
          isRequiredAsteriskShown={true}
          label={Type}
          onValueChange={handlePluginChange}
          options={sortedPluginUris}
          renderOption={renderPluginOption}
          renderSelectedValue={renderPluginOption}
        />

        {/* (If needed) Allowed values */}
        {isSelectedPluginUriNeedsAllowedValues && (
          <>
            {allowedValueRows.map((row, index) => {
              return (
                <div key={row.id} className="pl-20">
                  <RegrelloDraggableRowItem
                    index={index}
                    isDragEnabled={true}
                    moveRow={moveAllowedValue}
                    preview={<RegrelloChip>{row.value}</RegrelloChip>}
                    row={row}
                  >
                    <div className="flex flex-1 items-start">
                      <RegrelloControlledFormFieldTextMultiline
                        className="flex-1 mr-2"
                        controllerProps={{
                          control: form.control,
                          name: `allowedValues.${index}.value`,
                          rules: {
                            ...ValidationRules.REQUIRED,
                            ...ValidationRules.UNIQUE_IN({
                              otherValues: arrayRemoveAtIndex(latestAllowedValueRows, index).map(({ value }) => value),
                            }),
                          },
                        }}
                        dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_ALLOWED_VALUE}
                        helperText={
                          index === 0
                            ? "Enter a value or create multiple by pasting text that has multiple lines."
                            : undefined
                        }
                        isDefaultMarginsOmitted={index !== allowedValueRows.length - 1}
                        minimumRowCount={1}
                        onPaste={(event) => handleSelectOptionPaste(event, index)}
                      />

                      {/* Delete */}
                      <RegrelloButton
                        dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_DELETE_OPTION_BUTTON}
                        disabled={index === 0 && allowedValueRows.length === 1}
                        iconOnly={true}
                        intent="neutral"
                        onClick={() => removeAllowedValue(index)}
                        startIcon="close"
                        variant="ghost"
                      />
                    </div>
                  </RegrelloDraggableRowItem>
                </div>
              );
            })}

            {/* Add */}
            <div className="ml-26 flex justify-between">
              <RegrelloButton
                dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_ADD_OPTION_BUTTON}
                disabled={!selectedPlugin?.isCreateAndEditAllowed}
                intent="primary"
                loading={isAddingViaPaste}
                onClick={() => appendAllowedValue({ value: EMPTY_STRING })}
                startIcon="add"
                variant="ghost"
              >
                {AddOption}
              </RegrelloButton>

              {/* UX Nicety: Provide this button that alphabetizes the options on click. */}
              {selectedPlugin?.isCreateAndEditAllowed && allowedValueRows.length > 1 && (
                // (clewis): Put the margin on this outer div so that align="end" aligns properly.
                <div className="mr-11">
                  <RegrelloTooltipV4
                    align="end"
                    content={isOptionsAlphabetized ? AlphabetizeOptionsTooltip : undefined}
                    maxWidth={260}
                    side="top"
                  >
                    {/* (clewis): This span is necessary for the tooltip to work when the button is disabled. */}
                    <div>
                      <RegrelloButton
                        disabled={isOptionsAlphabetized}
                        loading={isSorting}
                        onClick={sortOptionsAlphabetically}
                        startIcon="text-field"
                        variant="ghost"
                      >
                        {AlphabetizeOptions}
                      </RegrelloButton>
                    </div>
                  </RegrelloTooltipV4>
                </div>
              )}
            </div>
          </>
        )}

        {/* (If needed) Field unit */}
        {isSelectedPluginUriNeedsFieldUnit && (
          <RegrelloControlledFormFieldSelect
            controllerProps={{
              control: form.control,
              name: "fieldUnit",
              rules: ValidationRules.REQUIRED,
            }}
            dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_UNIT}
            getOptionLabel={(fieldUnit) => getFieldUnitDisplayName(fieldUnit)}
            isRequiredAsteriskShown={true}
            label={CurrencySymbol}
            options={fieldUnits}
            renderOption={renderFieldUnitOption}
          />
        )}

        {selectedPlugin?.isValueConstraintEnabled() && selectedPlugin?.isDataFormatToggleVisible() && (
          <RegrelloControlledFormFieldSwitch
            className="ml-2"
            controllerProps={{
              control: form.control,
              name: "isValueConstraintsEnabled",
            }}
            dataTestId={DataTestIds.CREATE_FIELD_DIALOG_FIELD_DATA_FORMAT_SWITCH}
            disabled={!(selectedPlugin?.isCreateAndEditAllowed ?? true)}
            label={EMPTY_STRING}
            secondaryLabel={DataFormat}
          />
        )}
        {/* show the value constraints section if data format toggle is visible and checked, or if data format toggle is not visible but value constraints exist */}
        {((selectedPlugin?.isDataFormatToggleVisible() && isValueConstraintsEnabled) ||
          (!selectedPlugin?.isDataFormatToggleVisible() && selectedPlugin?.isValueConstraintEnabled())) &&
          renderPluginConstraints()}
      </div>
    );
  },
);

function getFieldUnitDisplayName(fieldUnit: FieldUnit) {
  return `${fieldUnit.symbol} ${fieldUnit.name}`;
}
